











































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'

class Condition {
  id: number
  field: string
  operator = 0
  value: string
}

class Product {
  id: number
  eontyreDescription: string
  currency: string
}

@Component({
  components: { ListView },
})
export default class NewOrEdit extends Vue {
  $refs: Vue['$refs'] & {
    autoProductForm: {
      validate: any
      reset: any
    }
  }

  private loading = false
  private isEdit = false
  private showProduct = false
  private overrideProductPrice = false
  private overrideProductName = false
  private overrideProductQuantity = false

  private autoProductTypes = []

  private product: Product = null

  private autoProduct = {
    id: '',
    event: 1,
    productId: null,
    overrideProductName: null,
    name: '',
    overrideProductPrice: null,
    productPrice: 0,
    overrideProductQuantity: null,
    quantity: 1,
    shopId: null,
    typeId: 1,
    conditions: {},
  }

  private conditions = {
    productType: new Condition(),
    articleNo: new Condition(),
    productCondition: new Condition(),
    tyreType: new Condition(),
    tyreIsRetreaded: new Condition(),
    rimMaterialType: new Condition(),
    vehicleType: new Condition(),
    diameter: new Condition(),
    width: new Condition(),
    supplier: new Condition(),
    webshop: new Condition(),
    customer: new Condition(),
  }

  private events = [
    {
      id: 1,
      description: this.$t('c:auto-products:Product is added to order'),
    },
    {
      id: 2,
      description: this.$t('c:auto-products:New order is created'),
    },
    {
      id: 3,
      description: this.$t('c:auto-products:Product added to webshop order'),
    },
    {
      id: 4,
      description: this.$t('c:auto-products:Product added to webshop cart'),
    },
  ]

  // operators as variables
  private isAny = {
    id: 0,
    text: 'is any',
    sign: '',
  }

  private equals = {
    id: 1,
    text: 'equals',
    sign: '=',
  }

  private gtoe = {
    id: 2,
    text: 'greater than or equals',
    sign: '>=',
  }

  private ltoe = {
    id: 3,
    text: 'less than or equals',
    sign: '<=',
  }

  private notEquals = {
    id: 4,
    text: 'not equals',
    sign: '!=',
  }

  private in = {
    id: 5,
    text: 'in',
    sign: '∈',
  }

  private notIn = {
    id: 6,
    text: 'not in',
    sign: '∉',
  }

  private isAnyEqualsOperators = this.generateOperators(
    [this.isAny.id, this.equals.id],
    [this.isAny.text, this.equals.text],
    [this.isAny.sign, this.equals.sign],
  )

  private multipleOperators = this.generateOperators(
    [this.isAny.id, this.equals.id, this.notEquals.id, this.in.id, this.notIn.id],
    [this.isAny.text, this.equals.text, this.notEquals.text, this.in.text, this.notIn.text],
    [this.isAny.sign, this.equals.sign, this.notEquals.sign, this.in.sign, this.notIn.sign],
  )

  private articleTyretypeOperators = this.generateOperators(
    [this.isAny.id, this.equals.id, this.in.id, this.notIn.id],
    [this.isAny.text, this.equals.text, this.in.text, this.notIn.text],
    [this.isAny.sign, this.equals.sign, this.in.sign, this.notIn.sign],
  )

  private allOperators = this.generateOperators(
    [this.isAny.id, this.equals.id, this.gtoe.id, this.ltoe.id, this.notEquals.id, this.in.id, this.notIn.id],
    [
      this.isAny.text,
      this.equals.text,
      this.gtoe.text,
      this.ltoe.text,
      this.notEquals.text,
      this.in.text,
      this.notIn.text,
    ],
    [
      this.isAny.sign,
      this.equals.sign,
      this.gtoe.sign,
      this.ltoe.sign,
      this.notEquals.sign,
      this.in.sign,
      this.notIn.sign,
    ],
  )

  private productTypeRules = [
    (v) =>
      this.conditions.productType.operator === 0 ||
      (this.conditions.productType.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private articleNoRules = [
    (v) =>
      this.conditions.articleNo.operator === 0 ||
      (this.conditions.articleNo.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
    (v) => /^[\d,]+$/.test(v) || this.$t('c:auto-products:Only numbers are allowed'),
  ]

  private productConditionRules = [
    (v) =>
      this.conditions.productCondition.operator === 0 ||
      (this.conditions.productCondition.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private tyreTypeRules = [
    (v) =>
      this.conditions.tyreType.operator === 0 ||
      (this.conditions.tyreType.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private tyreIsRetreadedRules = [
    (v) =>
      this.conditions.tyreIsRetreaded.operator === 0 ||
      (this.conditions.tyreIsRetreaded.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private rimMaterialTypeRules = [
    (v) =>
      this.conditions.rimMaterialType.operator === 0 ||
      (this.conditions.rimMaterialType.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private vehicleTypeRules = [
    (v) =>
      this.conditions.vehicleType.operator === 0 ||
      (this.conditions.vehicleType.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private diameterRules = [
    (v) =>
      this.conditions.diameter.operator === 0 ||
      (this.conditions.diameter.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
    (v) => /^[\d,.]+$/.test(v) || this.$t('c:auto-products:Only numbers are allowed'),
  ]

  private widthRules = [
    (v) =>
      this.conditions.width.operator === 0 ||
      (this.conditions.width.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
    (v) => /^[\d,.]+$/.test(v) || this.$t('c:auto-products:Only numbers are allowed'),
  ]

  private supplierRules = [
    (v) =>
      this.conditions.supplier.operator === 0 ||
      (this.conditions.supplier.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private webshopRules = [
    (v) =>
      this.conditions.webshop.operator === 0 ||
      (this.conditions.webshop.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private customerRules = [
    (v) =>
      this.conditions.webshop.operator === 0 ||
      (this.conditions.webshop.operator !== 0 && this.singleOrMultipleValuesValidate(v)) ||
      this.$t('c:auto-products:Field is required'),
  ]

  private productTypes = []
  private productConditions = []
  private tyreTypes = []
  private tyreIsRetreadedOptions = []
  private rimMaterialTypes = []
  private vehicleTypes = []
  private suppliers = []
  private webshops = []

  private filteredCustomers = []

  private filteredProducts = []

  private productSearchLoading = false
  private productSearch = ''

  private customerSearchLoading = false
  private customerSearch = ''

  async created() {
    this.conditions.productType.field = 'product_type'
    this.conditions.articleNo.field = 'product_id'
    this.conditions.productCondition.field = 'product_condition'
    this.conditions.tyreType.field = 'tyre_type'
    this.conditions.tyreIsRetreaded.field = 'tyre_is_retreaded'
    this.conditions.rimMaterialType.field = 'rim_material_type'
    this.conditions.vehicleType.field = 'vehicle_type'
    this.conditions.diameter.field = 'diameter'
    this.conditions.width.field = 'width'
    this.conditions.supplier.field = 'supplier'
    this.conditions.webshop.field = 'webshop_id'
    this.conditions.customer.field = 'customer_id'

    if (this.$route.params.id !== undefined) {
      this.isEdit = true
      this.$axios.get('/v4/site/auto-products/' + this.$route.params.id).then((response) => {
        this.autoProduct = response.data.data
        this.overrideProductName = !!this.autoProduct.overrideProductName
        this.overrideProductPrice = this.autoProduct.overrideProductPrice != null
        this.overrideProductQuantity = !!this.autoProduct.overrideProductQuantity
        if (this.autoProduct.quantity === undefined) {
          this.autoProduct.quantity = 1
        }
        if (this.autoProduct.productId !== null) {
          this.productSearch = '#' + this.autoProduct.productId
          this.searchWithArticleNumber()
        }
        const conditions = response.data.data.conditions
        conditions.forEach((condition) => {
          condition.value = condition.value || ''
          if (condition.value.includes(',')) {
            condition.value = condition.value.replace(/['"\[\]]+/g, '')
            condition.value = condition.value.split(',').map((value) => parseInt(value.trim(), 10))
          }
          switch (condition.field) {
            case 'product_type':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.productType = { ...condition }
              break
            case 'product_id':
              this.conditions.articleNo = { ...condition }
              break
            case 'product_condition':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.productCondition = { ...condition }
              break
            case 'tyre_type':
              condition.value = parseInt(condition.value)
              this.conditions.tyreType = { ...condition }
              break
            case 'tyre_is_retreaded':
              condition.value = parseInt(condition.value)
              this.conditions.tyreIsRetreaded = { ...condition }
              break
            case 'rim_material_type':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.rimMaterialType = { ...condition }
              break
            case 'vehicle_type':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.vehicleType = { ...condition }
              break
            case 'diameter':
              this.conditions.diameter = { ...condition }
              break
            case 'width':
              this.conditions.width = { ...condition }
              break
            case 'supplier':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.supplier = { ...condition }
              break
            case 'webshop_id':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.conditions.webshop = { ...condition }
              break
            case 'customer_id':
              if (!condition.value.includes(',') && !Array.isArray(condition.value)) {
                condition.value = parseInt(condition.value)
              }
              this.$axios.get('v4/site/customers/search?ids=' + condition.value.toString()).then((response) => {
                this.filteredCustomers = response.data.data
              })
              this.conditions.customer = { ...condition }
              break
            default:
              break
          }
        })
      })
    }
    await this.load()

    this.tyreIsRetreadedOptions = [
      {
        id: 0,
        text: this.$t('No'),
      },
      {
        id: 1,
        text: this.$t('Yes'),
      },
    ]
  }

  private async load() {
    this.loading = true

    await Promise.all([
      this.getProductTypes(),
      this.getProductConditions(),
      this.getTyreTypes(),
      this.getRimMaterialTypes(),
      this.getVehicleTypes(),
      this.getSuppliers(),
      this.getAutoProductProducts(),
      this.getWebshops(),
    ]).finally(() => {
      this.loading = false
    })
  }

  private show(value, id) {
    if (Array.isArray(value)) {
      return !!(value.includes(id) || value.includes(28))
    } else {
      // eslint-disable-next-line eqeqeq
      return value == id || value == 28
    }
  }

  private singleOrMultipleValuesValidate(value) {
    if (Array.isArray(value)) {
      return value.length > 0
    } else {
      return value !== null && value !== undefined && value !== ''
    }
  }

  @Watch('conditions.productType.operator')
  onProductTypeOperatorChanged(newOperator) {
    this.conditions.productType.value = this.convertToValueArray(this.conditions.productType.value, newOperator)
  }

  @Watch('conditions.vehicleType.operator')
  onVehicleTypeOperatorChanged(newOperator) {
    this.conditions.vehicleType.value = this.convertToValueArray(this.conditions.vehicleType.value, newOperator)
  }

  @Watch('conditions.productCondition.operator')
  onProductConditionOperatorChanged(newOperator) {
    this.conditions.productCondition.value = this.convertToValueArray(
      this.conditions.productCondition.value,
      newOperator,
    )
  }

  @Watch('conditions.diameter.operator')
  onDiameterOperatorChanged(newOperator) {
    this.conditions.diameter.value = this.convertToValueArray(this.conditions.diameter.value, newOperator)
  }

  @Watch('conditions.width.operator')
  onWidthOperatorChanged(newOperator) {
    this.conditions.width.value = this.convertToValueArray(this.conditions.width.value, newOperator)
  }

  @Watch('conditions.supplier.operator')
  onSupplierOperatorChanged(newOperator) {
    this.conditions.supplier.value = this.convertToValueArray(this.conditions.supplier.value, newOperator)
  }

  @Watch('conditions.webshop.operator')
  onWebshopOperatorChanged(newOperator) {
    this.conditions.webshop.value = this.convertToValueArray(this.conditions.webshop.value, newOperator)
  }

  @Watch('conditions.customer.operator')
  onCustomerOperatorChanged(newOperator) {
    this.conditions.customer.value = this.convertToValueArray(this.conditions.customer.value, newOperator)
  }

  convertToValueArray(value, operator) {
    if ((operator === 5 || operator === 6) && !Array.isArray(value)) {
      return value ? [value] : []
    } else {
      return value
    }
  }

  private onProductChange() {
    this.productSearch = '#' + this.autoProduct.productId
    this.searchWithArticleNumber()
  }

  private getAutomaticQuantityInfo() {
    return this.$t(
      'c:auto-products:Example - If you add 2 tyres to order, 2 of %s will be added, if you add 4 tyres to order 4 of %s will be added, and so on',
    ).replace('%s', this.product.eontyreDescription)
  }

  private getOverrideQuantityInfo() {
    const quantity = this.autoProduct.overrideProductQuantity !== null ? this.autoProduct.overrideProductQuantity : 1
    return this.$t('c:auto-products:Example - No matter how many tyres you add to order, %q of %s will be added.')
      .replace('%q', quantity)
      .replace('%s', this.product.eontyreDescription)
  }

  private searchWithArticleNumber() {
    this.$axios
      .get('v4/site/auto-products/products/get-product/' + this.productSearch.replace('#', ''))
      .then((response) => {
        this.product = response.data.data.data
        this.product.currency = response.data.data.currency
        this.autoProduct.productId = this.product.id
        this.showProduct = true
      })
      .catch((err) => {
        this.showProduct = false
        vxm.alert.onAxiosError(err)
      })
  }

  private getOperatorSign(operatorId) {
    const operator = this.allOperators.find((op) => op.id === operatorId)
    return operator ? operator.sign : ''
  }

  generateOperators(ids, descriptions, signs) {
    return ids.map((id, index) => ({
      id,
      operator: this.$t('c:auto-products:' + descriptions[index]),
      sign: signs[index],
    }))
  }

  private async getWebshops() {
    this.$axios.get('v4/site/get-webshops').then((response) => {
      this.webshops = response.data.data
    })
  }

  private async getAutoProductProducts() {
    this.$axios.get('v4/site/products/enums/types-for-auto-product').then((response) => {
      this.autoProductTypes = response.data.data
    })
  }

  private async getProductTypes() {
    this.$axios.get('v4/site/products/types?includeShop=1&legacy=1&perPage=500').then((response) => {
      this.productTypes = response.data.data
    })
  }

  private async getProductConditions() {
    this.$axios.get('v4/site/products/enums/conditions').then((response) => {
      this.productConditions = response.data.data
    })
  }

  private async getTyreTypes() {
    this.$axios.get('v4/site/products/enums/tyre-types').then((response) => {
      this.tyreTypes = response.data.data
    })
  }

  private async getRimMaterialTypes() {
    this.$axios.get('v4/site/products/enums/rim-material-types').then((response) => {
      this.rimMaterialTypes = response.data.data
    })
  }

  private async getVehicleTypes() {
    this.$axios.get('v4/site/products/enums/vehicle-types').then((response) => {
      this.vehicleTypes = response.data.data
    })
  }

  private async getSuppliers() {
    this.$axios.get('v4/site/suppliers/connected').then((response) => {
      this.suppliers = response.data.data
    })
  }

  @Watch('productSearch')
  @Debounce(300)
  private onProductSearchChanged(val) {
    this.productSearchLoading = true
    if (val && val !== '' && val.length > 2) {
      if (!val.startsWith('#')) {
        this.$axios
          .get('v4/site/auto-products/products/search-products?nameSearch=' + this.productSearch)
          .then((response) => {
            this.filteredProducts = response.data.data
          })
      }
    } else {
      this.filteredProducts = []
    }
    this.productSearchLoading = false
  }

  @Watch('customerSearch')
  @Debounce(300)
  private onCustomerSearchChanged() {
    if (this.customerSearch && this.customerSearch.trim() !== '') {
      this.customerSearchLoading = true
      this.$axios
        .get('v4/site/customers/search?search=' + this.customerSearch)
        .then((response) => {
          this.filteredCustomers = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not search customers')
        })
        .finally(() => {
          this.customerSearchLoading = false
        })
    }
  }

  private saveAutoProduct() {
    if (this.$refs.autoProductForm.validate()) {
      if (this.autoProduct.productId === null) {
        vxm.alert.onAxiosError('Product cannot be empty')
        return
      }

      const formatValue = (condition: Condition) => {
        if (Array.isArray(condition.value) && condition.value.length > 0) {
          condition.value = condition.value.join(',')
        }
        return condition
      }

      Object.values(this.conditions).forEach((condition: Condition, index) => {
        this.autoProduct.conditions[index] = formatValue(condition)
      })

      this.autoProduct.conditions = this.conditions
      if (!this.overrideProductName) {
        this.autoProduct.overrideProductName = null
      }
      if (!this.overrideProductPrice) {
        this.autoProduct.overrideProductPrice = null
      }
      if (!this.overrideProductQuantity) {
        this.autoProduct.overrideProductQuantity = null
      }

      this.loading = true
      if (this.isEdit) {
        this.$axios
          .put('v4/site/auto-products/' + this.autoProduct.id, this.autoProduct)
          .then(() => {
            this.$router.push({ name: 'AutoProducts/List', params: { id: this.$route.params.id } })
            console.log('success auto product and conditions update')
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Failed updating auto product')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$axios
          .post('v4/site/auto-products', this.autoProduct)
          .then(() => {
            this.$router.push({ name: 'AutoProducts/List', params: { id: this.$route.params.id } })
            console.log('success auto product and conditions insert')
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Failed saving auto product')
          })
          .finally(() => {
            this.loading = false
          })
      }
    } else {
      vxm.alert.error(this.$t('c:auto-products:Check field errors'))
    }
  }
}
